<template>
    <div v-if="loader">
        Loading...
    </div>
    <div v-if="! loader">
        <CourierGlobalHeader :global_err="global_err" />
        <div v-if="global_err == ''">
            <!--<div class="singup_comman_banner">-->
                <div class="comman_banner_e">
                    <div class="container">
                        <div class="banner_logo">
                            <img src="images/simpo_logo.svg" alt="Simpo" title="Simpo">
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="courier_point_new_package_e_page">
                        <div class="new_package_title">
                            <h4>הפקדת חבילה {{ point_name }}</h4>
                        </div>
                        <div class="new_package_title">
                            <h3>תאים פנויים</h3>
                        </div>
                        
                        <div class="statistics_box_wrap row">
                            <div class="statistics_box" >  
                                <div v-for="free_box in free_boxes" :key="free_box.size" class="statistics_detail">
                                    <div class="statistics_title">
                                        <h6>{{ getSizeShortDescription(free_box.size) }}</h6>
                                    </div>
                                    <div class="statistics_info">
                                        <p>{{ free_box.total_free }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!--<form @submit="login" class="singup_form" >
                            <p class="error_text" >{{errs}}</p>
                            <div class="form-group">
                                <label>טלפון</label>
                                <input type="text" class="form-control"  v-model="phone">
                            </div>
                            <div class="form-group">
                                <label>סיסמא</label>
                                <input type="password" class="form-control" v-model="password">
                            </div>
                            <div class="form-group">
                                <input type="submit" class="comman_anchor" value="כניסה">
                            </div>
                        </form>-->
                    </div>
                </div>
                
                <div class="new_package_title">
                    <h3>פרטי חבילה/ות חדשה/ות</h3>
                </div>
                <div class="container">
                    <div class="courier_point_new_package_e_page">
                        <form @submit="to_box" class="courier_point_new_package_e_form">
                            <p class="error_text" style="display: none">אופס, שכחת משהו</p>
                            <p class="error_text">{{ errs }}</p>
                            <div class="courier_point_new_package_e_form-group">
                                <label>נא להזין את מספר חבילה/ות</label>
                                <input id="packageNumber" type="text" class="form-control"
                                    v-model="package_number"
                                />
                            </div>
                            <div class="courier_point_new_package_e_form-group">
                                <label>נא להזין את מספר הטלפון של מקבל/ת החבילה/ות</label>
                                <!--<v-select id="phoneList" v-model="selectedPhone"
                                    :options="phones.map(phone => phone.description)"
                                    
                                    label="Select phones">
                                </v-select>-->
                                <v-select id="phoneList" v-model="selectedPhone"
                                :options="phones" :value="phones.phone_number"    
                                    
                                    label="description">
                                </v-select>
                                <!--<select id="mySelect" name="" class="form-control" v-model="phones">
                                    <option v-for="phone in phones" :key="phone.phone" :value="phone.description">
                                        {{ phone.description }}
                                    </option>
                                </select>-->
                            </div>
                            <div class="courier_point_new_package_e_form-group">
                                <label>בחירת גודל תיבה</label>
                                <select id="sizeList" name="" class="form-control" v-model="size">
                                    <option v-for="box in free_boxes" :key="box.size" :value="box.size">
                                    {{ getSizeLongDescription(box.size) }}
                                    </option>
                                </select>
                            </div>
                            <input type="submit" class="comman_anchor" value="הוספה" />
                        </form>
                    </div>
                     <!--<span class="warning_message">שימו לב! עם לחיצה על כפתור “הוספה“ תישלח הודעה מיידית ללקוח</span>-->
                </div>
            <!--</div>-->
        </div>
    </div>
</template>

<script>
import BackendModel from "../../models/BackendModel";
import CourierGlobalHeader from "./CourierGlobalHeader";
import vSelect from "vue-select";
import('./courier/css/style.css')
export default {
  name: 'CourierPointNewPackageE',
  components: {
    CourierGlobalHeader,
    vSelect,
  },
  data() {
    return {
      loader: false,
      point_id : 0,
      point_name : '',
      view_data : [],
      free_boxes :[],
      package_number : '',
      phones: [],
      selectedPhone : '',
      size: '',
      
      //password: '',
      errs:'',
      global_err : ''
    }
  },
    async mounted () {
        const backendModel = new BackendModel()
        this.loader = true
        this.point_id = this.$route.query.id
        const point_id = this.point_id
        /*let courier_session_id = backendModel.getCourierSessionId()
        let authRes = await backendModel.courier_Request('/Api/service/backoffice/courier_auth', {courier_session_id})
        if (typeof authRes == 'undefined') {
        this.global_err = 'Backend is down?'
        } else if (authRes.data.res == 'ERROR') {
        this.global_err = authRes.data.e
        } else if (authRes.data.res == 'OK') {
        await this.$router.push({ path: '/courier/courier_home' })
        }*/
        let queryRes = await backendModel.courierE_Request('/Api/service/backoffice/get_courier_point_new_package_ext_info', {point_id})
        console.log('queryRes:')
        console.log(queryRes)
        this.view_data = queryRes.data.view_data
        console.log('view_data:')
        console.log(this.view_data)
        this.free_boxes = this.view_data.free_boxes
        console.log('free_boxes:')
        console.log(this.free_boxes)
        this.point_name = this.view_data.point_name[0].name;
        console.log('point_name:')
        console.log(this.point_name)
        /*let phones = await backendModel.point_Request(
            "/Api/service/backoffice/point_get_phones",
            {}
        );*/
        this.phones = this.view_data.phones;
        console.log(this.phones);
       /*if (typeof queryRes == 'undefined') {
        this.global_err = 'Backend is down?'
        } else if (queryRes.data.res == 'ERROR') {
        this.global_err = queryRes.data.e
        } else if (queryRes.data.res == 'OK') {
        await this.$router.push({ path: '/courier/courier_unit_package_deposit_e', query: { id: this.point_id } })
        }*/
        this.loader = false  
        this.errs = ''
     
   },
    methods : {
        getSizeShortDescription(size) {
            const sizeMapping = {
                's': 'קטן',
                'm': 'בינוני',
                'l': 'גדול',
                'j': 'ג׳מבו',
            };
            return sizeMapping[size] || size;
        },
        getSizeLongDescription(size) {
            const sizeMapping = {
                's': 'קטן (45x40)',
                'm': 'בינוני (24.5x40)',
                'l': 'גדול (11.5x40)',
                'j': 'ג׳מבו (90x90)',
            };
            return sizeMapping[size] || size;
        },
        createQueryStringFromSize (inputSize, free_boxes) {
            const getOrderValue = function (size) {
                const sizeOrder = {
                    s: 1,
                    m: 2,
                    l: 3,
                    j: 4
                };
                sizeOrder[size];
            };

            const inputOrder = getOrderValue(inputSize);
            const largerSizes = free_boxes
                .filter(box => getOrderValue(box.size) > inputOrder)
                .map(box => box.size);

            return largerSizes;
        },

        async to_box(e) {
            e.preventDefault();

            console.log('this.selectedPhone')
            console.log(this.selectedPhone)
            console.log('this.selectedPhone.phone_number')
            console.log(this.selectedPhone.phone_number)

            const backendModel = new BackendModel()
            //'/Api/service/backoffice/get_courier_point_new_package_ext_info', {point_id})
            //"/Api/service/backoffice/point_find_box"
            let res = await backendModel.courierE_Request(
                "/Api/service/backoffice/courier_find_box_ext",
                {
                    point_id: this.point_id,                    
                    package_number: this.package_number,
                    phone_number: this.selectedPhone.phone_number,
                    // phone_number: this.selectedPhone.split(" -")[0],
                    size: this.size,
                }
            );
            console.log('res:')
            console.log(res)
            
            if (res.data.err == "less_data") {
                this.errs = "אופס, שכחת משהו";
            }
            if (res.data.err == "user_does_not_exist") {
                this.errs = "המשתמש לא קיים במערכת";
            }
            if (res.data.err == "NOT_FREE_BOX") {
                this.errs = "אין תיבות פנויות";
            }
            if (res.data.res == "NOK" && res.data.err == null) {
                this.errs = "שגיאה";
            }
            if (res.data.res == "OK") {
                let box_id = res.data.view_data.id;
                let box_number = res.data.view_data.number;

                let point_id = this.point_id;                   
                let package_number = this.package_number;
                let phone_number = this.selectedPhone.phone_number;

                let size = this.size;
                let free_boxes = this.free_boxes;
                let free_boxes_query_string_format = this.createQueryStringFromSize(size, free_boxes);
                console.log('free_boxes_query_string_format:')
                console.log(free_boxes_query_string_format)
                //let size =  this.size;
                await this.$router.push({
                    path: "/courier/courier_point_new_package_validation_e",
                    query: { box_id, box_number
                        , point_id, package_number, phone_number 
                        , size, free_boxes
                     },
                    
                });
                this.loader = false;
            }
        },
        /*async login(e) {
             e.preventDefault()
             const backendModel = new BackendModel()
             let res = await backendModel.courier_Request('/Api/service/backoffice/courier_login', {"phone" : this.phone, "password" : this.password});
             if (res.data.res == "NOK" && res.data.err == "less_data" ){
                this.errs = 'אופס, שכחת משהו'
            }
            if(res.data.res == "NOK"){
                this.errs = 'שגיאה'
            }
             if(res.data.res == "OK"){
                 let session_id = res.data.session_id
                  let CookieDate = new Date
                  CookieDate.setFullYear(CookieDate.getFullYear() + 10)
                  document.cookie = 'courier_session_id =' + session_id +'; expires=' + CookieDate.toUTCString() + ';'
                await this.$router.push({ path: '/courier/courier_home' })
                this.loader_prop = false
            }
        },*/
    }
}
</script>

<style>
@import 'courier/css/style.css';
</style>
